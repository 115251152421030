import React from 'react'
import { Grid } from '@mui/material'
import Logo from './logo'
import Slight from './slight'

export default function Build() {

    return <Grid container spacing={2} sx={{ height: `calc(100vh - 50px)`, minHeight: '700px' }}>
        <Grid
            container xs={12} md={6}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Logo scale="120%" />
            </Grid>
        </Grid>
        <Grid
            container xs={12} md={6}
        >
            <Slight />
        </Grid>
    </Grid>
}