import { theme } from './routes/theme'
import Routes from './routes';
import { CssBaseline, Toolbar } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Topbar from './routes/topbar';

function App() {
	return <div className="App">
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Topbar />
			<Toolbar />
			<Routes />
		</ThemeProvider>
	</div>
}

export default App;
