import React from 'react'
import { Toolbar, AppBar, Typography } from '@mui/material'

export default function Topbar() {

    return <AppBar position="fixed">
        <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Build with MazeYard
            </Typography>
        </Toolbar>
    </AppBar>
}