import { Typography, Grid, Link } from '@mui/material'
import React from 'react'
import Logo from './logo'

export default function Footer() {

    const footerLinks = [
        {
            name: 'Service66',
            url: 'https://service66.mazeyard.com'
        },
        {
            name: 'Projects',
            url: 'https://www.mazeyard.com/projects'
        },
        {
            name: 'Contact Us',
            url: 'https://www.mazeyard.com/contact'
        },
    ]

    const footerLinks2 = [
        {
            name: 'Documentation',
            url: 'https://howto.build.withmazeyard.com/'
        },
        {
            name: 'Developer Console',
            url: 'https://console.build.withmazeyard.com/'
        },
    ]

    return <Grid
        container
        sx={{
            padding: '60px 40px',
            bgcolor: 'text.disabled'
        }}
    >
        <Grid
            container
            xs={12} md={6}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 5, marginBottom: 5 }}
        >
            <Logo
                hideBuild
                link='https://www.withmazeyard.com/'
            />
            <br />
            <br />
            <Typography variant="h6">
                A subsidiary of&nbsp;
                <Link
                    href="https://www.mazeyard.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    MazeYard
                </Link>
            </Typography>
        </Grid>
        <Grid
            container xs={12} md={6}
            sx={{ marginTop: 5, marginBottom: 5 }}
        >
            <Grid
                item
                xs={12} md={6}
                sx={{ marginTop: 5, marginBottom: 5 }}
            >
                {
                    footerLinks2.map((link, index) => {
                        return <Typography variant='body1' sx={{ marginBottom: 1 }} key={index}>
                            <Link
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: 'text.secondary', textDecoration: 'none' }}
                            >
                                {link.name}
                            </Link>
                        </Typography>
                    })
                }
            </Grid>
            <Grid
                item
                xs={12} md={6}
                sx={{ marginTop: 5, marginBottom: 5 }}
            >
                {
                    footerLinks.map((link, index) => {
                        return <Typography variant='body1' sx={{ marginBottom: 1 }} key={index}>
                            <Link
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: 'text.secondary', textDecoration: 'none' }}
                            >
                                {link.name}
                            </Link>
                        </Typography>
                    })
                }
            </Grid>
        </Grid>
    </Grid>
}