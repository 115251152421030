import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { Circle } from '@mui/icons-material'

export default function Services(props) {

    const { displayList, textColor, bgColor, headerText, underText } = props

    return <Box
        sx={{
            bgcolor: bgColor,
            padding: '60px 40px',
        }}
    >
        <Typography
            variant="h4"
            sx={{
                color: textColor
            }}
            align="left"
        >
            {headerText}
        </Typography>
        <List
            dense={true}
            sx={{ color: textColor }}
        >
            {
                displayList.map((item) => {
                    return <ListItem>
                        <ListItemIcon>
                            <Circle style={{ fontSize: '80%', color: textColor }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1" sx={{ color: textColor }}>
                                    {item}
                                </Typography>
                            }
                        />
                    </ListItem>
                })
            }
        </List>
        <Typography
            variant='h6'
            color={textColor}
            align='left'
            sx={{
                marginTop: 2
            }}
        >
            {underText}
        </Typography>
    </Box>
}