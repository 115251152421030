import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Logo(props) {

    var { color, scale, hideBuild, link } = props
    if (!color) {
        color = 'black'
    }
    if (!scale) {
        scale = '100%'
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            overflow: 'hidden', color: color, width: '220px', transform: `scale(${scale})`,
            cursor: 'pointer',
        }}
        onClick={() => {
            if (link) {
                window.location.href = link
            }
        }}
    >
        {
            hideBuild ? <></> : <Typography
                variant="h3"
                sx={{ letterSpacing: '28px', marginRight: '-28px', borderBottom: `1px solid ${color}`, marginBottom: '-5px' }}
            >
                Build
            </Typography>
        }
        <Typography variant="h6"
            sx={{ letterSpacing: '52px', marginRight: '-50px', padding: 0 }}>
            WITH
        </Typography>
        <Typography
            variant="h3"
            sx={{ marginTop: '-6px', borderTop: `1px solid ${color}`, width: '220px' }}
        >
            MazeYard
        </Typography>
    </Grid>
}